import React, { lazy, Suspense, useState, useEffect } from 'react';
import { Routes, Route } from 'react-router-dom';
import './App.scss';
import Header from './components/Header/Header';
import Footer from './components/Footer/Footer';
import Loader from './components/Loader/Loader';
import { Strings } from './Strings';
import { toast } from 'react-toastify';
import './components/Toast/Toast.scss';
import AppContext from './AppContext';
import { IntercomProvider } from 'react-use-intercom';
import { MuiThemeProvider, createTheme } from '@material-ui/core/styles';

const Home = lazy(() => import('./pages/home/Home'));
const Account = lazy(() => import('./pages/account/Account'));
const Pricing = lazy(() => import('./pages/pricing/Pricing'));

toast.configure();

const theme = createTheme({
  palette: {
    primary: {
      main: '#1DA0BC'
    }
  }
});

const App = () => {
  const [showIntercom, setIntercom] = useState(false);

  const intercomToggle = {
    showIntercom: showIntercom,
    setIntercom,
  };

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    let referrer_code = params.get("referrer");
    if (referrer_code) {
      localStorage.setItem("referrer_code", referrer_code);
    }
  }, []);

  return (
    <AppContext.Provider value={intercomToggle}>
      <MuiThemeProvider theme={theme}>
        <IntercomProvider
          appId="g156861r"
          onHide={() => {
            setIntercom(false);
          }}
          onShow={() => {
            setIntercom(true);
          }}
          autoBoot
          initializeDelay={500}
        >
          <Header />
          <Suspense fallback={
            <Loader
              head={Strings.GENERIC_LOADER_HEAD}
              subHead={Strings.GENERIC_LOADER_SUBHEAD}
              small={true} />
          }>

            <Routes>
              <Route path="/*" element={<Home />} />
              <Route path="/pricing" element={<Pricing />} />
              <Route path="/account/*" element={<Account />} />
            </Routes>
          </Suspense>
          <Footer />
        </IntercomProvider>
      </MuiThemeProvider>
    </AppContext.Provider >
  );
}

export default App;
