import React from "react";
import "./Footer.scss";
import { useWindowSize } from "../../app/hooks";
import { Strings } from "../../Strings";

const Footer = () => {
    const { width } = useWindowSize();

    return (
        <div className="footerBackground">
            <div className="col">
                <div className="colFooter-1">
                    <div className="socialMediaIcons" style={{ whiteSpace: "nowrap" }}>
                        <a
                            id="link"
                            href="https://twitter.com/aftershootco"
                            target="_blank"
                            rel="noreferrer"
                        >
                            <img src="/assets/images/twitter.svg" alt="Twitter" style={{ width: "16px" }} />
                        </a>
                        <span className="media-separator">/</span>
                        <a
                            id="link"
                            href="https://www.instagram.com/aftershootco/"
                            target="_blank"
                            rel="noreferrer"
                        >
                            <img src="/assets/images/instagram.svg" alt="Instagram" style={{ width: "16px" }} />
                        </a>
                        <span className="media-separator">/</span>
                        <a
                            id="link"
                            href="https://www.facebook.com/aftershootco"
                            target="_blank"
                            rel="noreferrer"
                        >
                            <img src="/assets/images/facebook.svg" alt="Facebook" style={{ width: "16px" }} />
                        </a>
                        <span className="media-separator">/</span>
                        <a
                            href="https://www.youtube.com/channel/UCvDl1D6YafsMBJOyAXDZU0A"
                            target="_blank"
                            rel="noreferrer"
                        >
                            <img src="/assets/images/youtube.svg" alt="Youtube" style={{ width: "16px" }} />
                        </a>
                    </div>

                    <div id="footerLogo">
                        <a href="https://aftershoot.com/">
                            <span
                                style={{
                                    fontWeight: 500,
                                    fontSize: "1.6rem",
                                    color: "#00a8b9",
                                }}
                            >
                                {Strings.AFTERSHOOT}
                            </span>
                        </a>
                    </div>
                </div>

                <div className="colFooter-2" style={{ whiteSpace: "nowrap" }}>
                    <span
                        style={{ display: "block", color: "#3a3a3a", marginBottom: "1rem" }}
                    >
                        <strong>{Strings.QUERIES}</strong>
                    </span>
                    <span
                        style={{
                            display: "block",
                            marginBottom: "0.25rem",
                            color: "#3a3a3a",
                        }}
                    >
                        {Strings.EMAIL_US}&nbsp;
                        <a
                            href="mailto:hello@aftershoot.com"
                            style={{ color: "#00a8b9", fontWeight: 500 }}
                        >
                            {Strings.EAMIL_ID}
                        </a>
                    </span>
                </div>

                <div className="colFooter-3"></div>
                <div className="colFooter-4">
                    {width >= 491 ? (
                        <span
                            style={{
                                color: "#3a3a3a",
                                marginBottom: "0.5rem",
                                marginTop: "1rem",
                            }}
                        >
                            <strong>{Strings.MORE}</strong>
                        </span>
                    ) : (
                        <span style={{ color: "#3a3a3a", marginBottom: "1rem" }}>
                            <strong>{Strings.MORE}</strong>
                        </span>
                    )}
                    <span style={{ display: "block", marginBottom: "0.25rem" }}>
                        <a
                            href="https://aftershoot.com/about/"
                            style={{ color: "#3a3a3a" }}
                        >
                            {Strings.ABOUT}
                        </a>
                    </span>
                    <span>
                        <a
                            href="https://aftershoot.com/privacy/"
                            style={{ color: "#3a3a3a" }}
                        >
                            {Strings.PRIVACY}
                        </a>
                    </span>
                </div>
            </div>

            <div className="subfooter">
                <div
                    style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "flex-start",
                    }}
                >
                    <span id="subfooterDetails">
                        {Strings.RIGHTS}
                    </span>
                </div>
            </div>
        </div>
    );
};

export default Footer;