import React from "react";
import "./Loader.scss";
import LinearProgress from "@material-ui/core/LinearProgress";

const Loader = (props: { small?: boolean, head: string, subHead: string }) => {
    return (
        <div
            className="payment"
            style={{
                height: props.small ? "fit-content" : "80vh",
            }}
        >
            <div
                style={{
                    alignSelf: "center",
                    marginBottom: "2.5rem",
                }}
            >
                <img src="/assets/images/LogoOne.svg" alt="AfterShoot" />
            </div>
            <div className="text-h4 text-bold text-333 heading">{props.head}</div>
            <div className="text-h5 text-666">{props.subHead}</div>
            <LinearProgress
                id="progressBar"
                color="primary"
                style={{ background: "#F2F2F2" }}
            />
        </div>
    );
};

export default Loader;
