export const Strings = {
    AFTERSHOOT: "AfterShoot",
    QUERIES: "Have any Queries?",
    EMAIL_US: "Email us at",
    EAMIL_ID: "hello@aftershoot.com",
    CALL_US: "Call us at",
    CALL: "+1 (603) 204-4917",
    MORE: "More",
    ABOUT: "About",
    PRIVACY: "Privacy Policy",
    RIGHTS: "© 2021, AfterShoot Inc. All rights reserved",
    YEAR: "Yearly",
    MONTH: "Monthly",
    PLANSPRICE: "Plans & Pricing",
    PLANSPRICE_SUBHEAD_ONE:
        "Aftershoot automates and handles the most monotonus, tedious,and",
    PLANSPRICE_SUBHEAD_TWO: "time-consuming part of your workflow!",
    SAVE: "Save up to 33% on annual plans",
    CROP_PIC: "Crop Profile Picture",
    SAVEBUTTON: "Save",
    CANCEL: "Cancel",
    VERIFY_THANKS: "Thanks for signing up to Aftershoot.",
    VERIFY_PLEASE: "Please verify your email address.",
    ACCOUNT_DETAILS: "Account Details",
    REFER_EARN: "Refer and Earn",
    USE_CREDIT: "Use these credits to upgrade your plan",
    FRIENDS_SHARE: "Share the code with your friends and earn AfterShoot credits for your next purchase.",
    REFER_CODE: "www.aftershoot.co/hangit29",
    CREDITS: "$100.00 Credits",
    HOW_WORK: "How it works?",
    ANY_QUESTION: " Have any questions?",
    MY_SUBSCRIPTION: "My Subscriptions",
    HELP_SUPPORT: "Help and Support",
    SIGN: "Sign in to continue",
    CREATE_ACC: "Create Account",
    RETURN: "Return to Sign in",
    EMAIL_RECEIVE:
        "You will receive an email with a link to create new password shortly.",
    REQUEST: "Request Sent Successfully",
    RESET: "Reset Now",
    POPUPCLOSE:
        "The popup has been closed by the user before finalizing the operation.",
    NEW_TO_AFTERSHOOT: "New to AfterShoot?",
    SHOOT: "Shoot More,",
    CULL: "Cull Less",
    LOGO_HEAD_ONE: "AfterShoot is fastest and the easiest way to select, rate,",
    LOGO_HEAD_TWO: "and find your best photos",

    VALUE_10: "10,000,000+",
    CULLED: "Photos Culled",
    VALUE_20: "20,000",
    HOURS: "Hours Saved",
    VALUE_5: "5,000",
    HAPPY: "Happy Photographers",
    SUCCESS_HEAD_ONE: "Congratulations and welcome to AfterShoot",
    SUCCESS_HEAD_TWO:
        "We're glad to have you part of our family, start enjoying your pro benefits now!",
    SPREAD: "Spread the word",
    FAILURE: "Failure",
    FAILURE_MESSAGE: "The login was cancelled, please try again",
    SUCCESS: "Success",
    SUCCESS_MESSAGE: "Verification link has been sent on your email!",
    EMAIL_ALREADY: "Email is already registered.",
    UNABLE: "Unable to create Account",
    PASSWORD_LINK_FAIL: "Password Reset Link has not been sent to your Email",
    PASSWORD_LINK_SUCCESS: "Password Reset Link has been sent to your Email.",
    PROFILE_FAIL: "Profile has not been updated.",
    PROFILE_SUCCESS: "Profile has been updated successfully.",
    VERIFY_LINK: "Verification link has been sent.",
    VERIFY_LINK_ALREADY: "Verification link has been already sent.",
    NO: "No",
    YES: "Yes",
    RECOMMENDED: "RECOMMENDED",
    BILLED: "billed",
    TRIAL: "14 days free trial 🎉",
    EMAIL_CHANGE: "Email changed successfully!",
    PASSWORD_CHANGE: "Password Changed Successfully",
    PASSWORD_UPDATE:
        "We have updated your password. Go back to sign in to your account.",
    VERIFED:
        "We have verified your email id. Go back to sign in to your account.",
    QUERY_HEADING: "Error encountered",
    QUERY_SUBHEADING: "The selected page mode is invalid.",
    TERMS_ONE: "By creating an account you accept our",
    TERMS_TWO: "terms & conditions and privacy policy.",
    ALREADY: "Already have an account?",
    ALREADY_IN_USE: "This email is already in use.",
    SIGN_NOW: "Sign in now",
    ALREADY_USE: "The email address is already in use by another account.",
    SAME_EMAIL: "This email is the same as the previous one.",
    CANNOT_EMPTY: "Cannot be left empty",
    PASSWORD_ERROR:
        "The password is invalid or the user does not have a password.",
    PASSWORD_INCORRECT: "The password you entered is incorrect",
    INVALID_ERROR: "Please enter a valid email",
    BAD_FORMAT: "The email address is badly formatted.",
    NOT_REG: "This email is not registered",
    NO_RECORD:
        "There is no user record corresponding to this identifier. The user may have been deleted.",
    UPGRADE: "Upgrade",
    FORGOTTEN: "Forgotten Password",
    HEADER_HOME: "Overview",
    HEADER_FAQ: "FAQs",
    HEADER_PRICING: "Pricing",
    HEADER_BLOGS: "Blog",
    MY_ACCOUNT: "My Account",
    SIGN_IN: "Sign In",
    SIGNING_IN: "Signing In",
    FULL_NAME: "Full Name",
    EMAIL_ADDRESS: "Email Address",
    PASSWORD: "Password",
    CREATING_ACC: "Creating Account",
    CREATE_FREE_ACC: "Create your free account",
    PASSWORD_MUST:
        "Password must contain atleast 8 letters including 1 number and 1 special character",
    PASSWORD_RESET_LINK: "Enter your email to receive a password reset link",
    SUBMITING: "Submiting",
    SUBMIT: "Submit",
    RECEIVE_PASSWORD_LINK:
        "You will receive an email with a link to create new password shortly.",
    LOADER_HEAD: "Logging in",
    LOADER_SUBHEAD: "Please wait while we log you in",
    GENERIC_LOADER_HEAD: "Loading",
    GENERIC_LOADER_SUBHEAD: "Please wait while the component is being loaded",
    SUBSCRIPTION_LOADER_SUBHEAD: "Please wait while we fetch your details",
    PAYMENT_LOADER_HEAD: "Initializing the payment",
    PAYMENT_LOADER_SUBHEAD: "Please wait, while we initialize the payment",
    SAVE_CHANGES: "Save Changes",
    SAVE_CROP: "Save",
    SAVING_CHANGES: "Saving Changes",
    EMAIL_ID_VERIFIED: "Email ID Verified",
    EMAIL_RECOVERED: "Email ID Recoverd",
    YOUR_EMAIL_RECOVERED:
        " Your Email have been recovered. Continue to your account.",
    PASSWORD_RESET: "Reset Password",
    NEW_PASSWORD: "Enter your new password",
    CONFIRM_PASSWORD: "Confirm your password",
    CONTACT: "Contact Us",
    COPY_SHARE: "Copy and share your referral code with your friend.",
    DISCOUNT_ONE: "Your friend gets",
    DISCOUNT_TWO: "10% discount",
    DISCOUNT_THREE: "on AfterShoot's Subscription.",
    FREE_ONE: "You get",
    FREE_TWO: "5% of their subscription amount",
    FREE_THREE: "in your wallet for your next purchase.",
    LINK_EXPIRE: "Link has been expired or incorrect",
    LOG_OUT_TEXT: "Are you sure you want to log out?",
    LOG_OUT: "Log Out",
    ERROR_404: "404. The page you are looking for does not exist.",
    NEW: "NEW",
    TRY_FREE: "Try for free",
};

export const Internals = {
    APP_URL: "https://www.aftershoot.com",
    REFERRALS_API_URL: "https://aws-api.aftershoot.com/referrals",
    SIB_UPDATE_API_URL: "https://aws-api.aftershoot.com/utils/sib-update",
    STRIPE_API_KEY: "pk_live_51Ik8AqCYznn1uZfNdl4HK600e51XtXjNOgq0ih4iZ0gw3RDINMrAsxKFta4IJ292m4lusayft6j7thzF1rEnLXeG0074LK8TTz",
}

export const PlansData = [
    {
        name: "Essentials",
        prices: null,
        points: [
            { text: "Free manual culling", blur: false },
            { text: "AI filters for detecting Closed Eyes and Blurred photos", blur: false },
            { text: "View key faces in the image with smart zoom", blur: false },
            { text: "One click import into Lightroom and Capture One", blur: false },
            { text: "Priority Support", blur: false },
            { text: "Regular updates", blur: false },
        ],
    },
    {
        name: "Professional",
        prices: {
            monthly: {
                id: "price_1IxujVCYznn1uZfNCMMKPReu",
                noTrialID: "price_1JpbLpCYznn1uZfNf8hlvK1q",
                price: "$14.99/mo",
            },
            yearly: {
                id: "price_1IxujVCYznn1uZfNYhWHoKa0",
                noTrialID: "price_1JpbLOCYznn1uZfNyDUFs4kI",
                price: "$9.99/mo",
            },
        },
        points: [
            { text: "Fully automated AI Culling", blur: false },
            { text: "Quick filters for Selections, Sneak Previews, and more...", blur: false },
            { text: "Ability to view Duplicates and Key Faces", blur: false },
            { text: "One click import into Lightroom and Capture One", blur: false },
            { text: "Priority Support", blur: false },
            { text: "Regular updates", blur: false },
        ],
    },
    {
        name: "Ultimate",
        prices: {
            monthly: {
                id: "price_1IxuqgCYznn1uZfNJ380Pnmi",
                price: "$19.99/mo",
            },
            yearly: {
                id: "price_1IxuqgCYznn1uZfNNM1ffXSM",
                price: "$14.99/mo",
            },
        },
        points: [
            { text: "All Features in the Professional plan", blur: false },
            { text: "Lorem ipsum dolor sit amet, consectetur adipiscing.", blur: true },
            { text: "Eiusmod tempor incididunt ut labore et dolore magna aliqua.", blur: true },
            { text: "Sed do eiusmod tempor incididunt.", blur: true },
            { text: "Incididunt ut labore.", blur: true },
            { text: "Regular updates", blur: false },
        ],
    },
];