import { initializeApp } from "firebase/app"

const firebaseConfig = {
    apiKey: "AIzaSyBRSw3neiRzAmz-QYo9I3wqr9duKEweGdc",
    authDomain: "aftershoot-co.firebaseapp.com",
    databaseURL: "https://aftershoot-co.firebaseio.com",
    projectId: "aftershoot-co",
    storageBucket: "aftershoot-co.appspot.com",
    messagingSenderId: "953041219166",
    appId: "1:953041219166:web:627adc0e0fc1b336ddcecf",
    measurementId: "G-WX4GH99F4Z",
};

export const firebaseApp = initializeApp(firebaseConfig);