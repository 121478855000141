import React, { useState, useEffect } from "react";
import "./Header.scss";
import { useWindowSize } from '../../app/hooks';
import { Strings } from "../../Strings";
import { firebaseApp } from "../../Firebase";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import StockDrawer from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import CloseIcon from "@material-ui/icons/Close";

const BrowserHeader = (props: { showHead: boolean, loggedIn: boolean }) => {
    const checkPricing = () => {
        if (window.location.pathname.includes("/pricing")) return true;
        return false;
    };

    const checkAccount = () => {
        if (window.location.pathname.includes("/account")) return true;
        return false;
    };

    return (
        <div
            className={props.showHead ? "header-fixed flex-row" : "header-sticky flex-row"}
            style={{ paddingLeft: "2.85rem", paddingRight: "2.85rem" }}
        >
            <div className="root flex-row">
                <a href="https://aftershoot.com/">
                    <div
                        className={props.showHead ? "image-fixed hover" : "image hover"}
                        style={{ width: "12rem" }}
                    >
                        <img
                            className={!props.showHead ? "image" : "image-fixed"}
                            src="/assets/images/AfterShootBrand.svg"
                            alt="AfterShoot"
                        />
                    </div>
                </a>
            </div>
            <div className="navbar flex-row">
                <div className="navbarItems flex-row">
                    <div className="navbarItem">
                        <a href="https://aftershoot.com/">
                            <span className="spanNavbar">
                                {Strings.HEADER_HOME}
                            </span>
                        </a>
                    </div>

                    <div className="navbarItem">
                        <a href="https://aftershoot.com/faqs/">
                            <span className="spanNavbar">
                                {Strings.HEADER_FAQ}
                            </span>
                        </a>
                    </div>
                    <div className="navbarItem">
                        <a href="/pricing">
                            <span
                                className="spanNavbar"
                                style={{ color: checkPricing() ? "#1DA0BC" : "#000" }}
                            >
                                {Strings.HEADER_PRICING}
                            </span>
                        </a>
                    </div>
                    <div className="navbarItem">
                        <a href="https://aftershoot.com/blogs/">
                            <span className="spanNavbar">
                                {Strings.HEADER_BLOGS}
                            </span>
                        </a>
                    </div>
                    {props.loggedIn ? (
                        <div className="navbarItem">
                            <a href="/account">
                                <span
                                    className="spanNavbar"
                                    style={{ color: checkAccount() ? "#1DA0BC" : "#000" }}
                                >
                                    {Strings.MY_ACCOUNT}
                                </span>
                            </a>
                        </div>
                    ) : (
                        <div className="navbarItem">
                            <a href="/login">
                                <span className="spanNavbar">
                                    {Strings.SIGN_IN}
                                </span>
                            </a>
                        </div>
                    )}
                </div>
                <div className="tryButton">
                    <button
                        className={"headerButton textSize"}
                        onClick={() => window.location.assign("/account")}
                    >
                        <span>{Strings.TRY_FREE}</span>
                    </button>
                </div>
            </div>
        </div>
    );
};

const MobileHeader = (props: { loggedIn: boolean }) => {
    const [open, setOpen] = useState(false);

    return (
        <div
            className="header-sticky flex-row"
            style={{ paddingLeft: "1.25rem", paddingRight: "1.25rem" }}
        >
            <div className="root flex-row">
                <div
                    style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                    }}
                >
                    <div id="menuIcon" className="flex-column" onClick={() => setOpen(true)}>
                        <span id="menuSpan"></span>
                        <span id="menuSpan"></span>
                    </div>
                    <div>
                        <a href="https://aftershoot.com/">
                            <img
                                className="icon"
                                src="/assets/images/FreeLogo.svg"
                                alt="Logo"
                            />
                        </a>
                    </div>
                </div>
                <Drawer open={open} loggedIn={props.loggedIn} closeDrawer={() => setOpen(false)} />
            </div>
            <div className="navbar flex-row">
                <div className="tryButton">
                    <button
                        className={"headerButton textSize"}
                        onClick={() => window.location.assign("/account")}
                    >
                        <span>{Strings.TRY_FREE}</span>
                    </button>
                </div>
            </div>
        </div>
    );
}

const Header = () => {
    const [showHead, handleShowHead] = useState(false);
    const [loggedIn, setLoggedIn] = useState(false);

    const auth = getAuth(firebaseApp);

    useEffect(() => {
        onAuthStateChanged(auth, (user) => {
            if (!user) return;
            setLoggedIn(true);
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        const handleScroll = () => {
            if (window.scrollY > 100) handleShowHead(true);
            else handleShowHead(false);
        };

        window.addEventListener("scroll", handleScroll);
        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);

    const { width } = useWindowSize();

    if (width < 768) return <MobileHeader loggedIn={loggedIn} />;

    return <BrowserHeader showHead={showHead} loggedIn={loggedIn} />;
};

const Drawer = (props: { open: boolean, loggedIn: boolean, closeDrawer: () => void }) => {
    const drawerItems = [
        { title: "Home", link: "https://aftershoot.com/" },
        { title: "FAQs", link: "https://aftershoot.com/faqs/" },
        { title: "Pricing", link: "/pricing" },
        { title: "Blog", link: "https://aftershoot.com/blogs/" },
        { title: "Sign in", link: "/login" },
    ];

    const drawerItemsLogin = [
        { title: "Home", link: "https://aftershoot.com/" },
        { title: "Pricing", link: "/pricing" },
        { title: "FAQs", link: "https://aftershoot.com/faqs/" },
        { title: "Blog", link: "https://aftershoot.com/blogs/" },
    ];

    const loggedInItems = [
        { title: "Subscriptions", link: "/account" },
        { title: "Account Details", link: "/account/details" },
        { title: "Refer and Earn", link: "/account/refer-earn" },
        { title: "Pricing", link: "/pricing" },
        { title: "Log out", link: "/account/logout" },
    ];

    return (
        <StockDrawer
            style={{ boxSizing: "unset", width: 300, fontFamily: "Lato" }}
            transitionDuration={300}
            anchor={"left"}
            open={props.open}
        >
            <CloseIcon style={{
                borderRadius: 100,
                background: "#f8f8f8",
                margin: "1rem",
                marginTop: "1.65rem",
                marginLeft: "1.4rem",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                padding: "0.8rem",
                cursor: "pointer",
            }} onClick={props.closeDrawer} />

            {props.loggedIn ? (
                <>
                    {" "}
                    <List style={{ width: 300 }}>
                        <ListItem>
                            <ListItemText
                                disableTypography
                                style={{
                                    color: "#999999",
                                    paddingLeft: "20px",
                                    fontSize: "1rem",
                                }}
                                primary="ACCOUNT"
                            />
                        </ListItem>
                        {loggedInItems.map(({ title, link }, i) => (
                            <a href={link} key={i}>
                                <ListItem button>
                                    <ListItemText
                                        disableTypography
                                        style={{
                                            color: link ? "#666666" : "#999999",
                                            paddingLeft: "20px",
                                            fontSize: "1rem",
                                        }}
                                        primary={title}
                                    />
                                </ListItem>
                            </a>
                        ))}
                    </List>
                    <div
                        style={{
                            height: "2px",
                            backgroundColor: "#f5f5f5",
                        }}
                    ></div>
                </>
            ) : null}
            {props.loggedIn ? (
                <List style={{ width: 300 }}>
                    <ListItem>
                        <ListItemText
                            disableTypography
                            style={{
                                color: "#999999",
                                paddingLeft: "20px",
                                fontSize: "1rem",
                            }}
                            primary="GENERAL"
                        />
                    </ListItem>
                    {drawerItemsLogin.map(({ title, link }, i) => (
                        <a href={link} key={title}>
                            <ListItem button>
                                <ListItemText
                                    disableTypography
                                    style={{
                                        color: "#666666",
                                        paddingLeft: "20px",
                                        fontSize: "1rem",
                                    }}
                                    primary={title}
                                />
                            </ListItem>
                        </a>
                    ))}
                </List>
            ) : (
                <List style={{ width: 300 }}>
                    {drawerItems.map(({ title, link }, i) => (
                        <a href={link} key={i}>
                            <ListItem button>
                                <ListItemText
                                    disableTypography
                                    style={{
                                        color: "#666666",
                                        paddingLeft: "20px",
                                        fontSize: "1rem",
                                    }}
                                    primary={title}
                                />
                            </ListItem>
                        </a>
                    ))}
                </List>
            )}
        </StockDrawer>
    );
};

export default Header;